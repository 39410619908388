import React from "react"

import Typography, { TypographyProps } from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { ISection } from "data/contentData/interfaces/mongodb/ISection"

import PortableText from "Components/PortableText/PortableText"

export default function Sections({
    sections,
    level = 1
}: {
    sections: ISection[] | undefined
    level?: number
}) {
    if (!sections) return null

    return (
        <>
            {sections.map(section => (
                <StyledSection key={section.url}>
                    {section.name && (
                        <Typography variant={levelToVariant(level)}>
                            {section.name}
                        </Typography>
                    )}
                    <PortableText value={section.blockContent} />
                    {section.sections && section.sections.length > 0 && (
                        <Sections
                            sections={section.sections}
                            level={level + 1}
                        />
                    )}
                </StyledSection>
            ))}
        </>
    )
}

/**
 * Map level to variant
 * @param level how deep the section is nested
 * @returns the variant for the Typography component
 */
function levelToVariant(level: number): TypographyProps["variant"] {
    switch (level) {
        case 1:
            return "h2"
        case 2:
            return "h3"
        case 3:
            return "h4"
        case 4:
            return "h5"
        case 5:
            return "h6"
        default:
            return "h2"
    }
}

const StyledSection = styled("section")(() => ({
    wordBreak: "break-word"
}))
