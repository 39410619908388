import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import Icon from "Components/Icon/Icon"
import ContentImage from "Components/Image/ContentImage"

export default function ArticleHeader({ page }: { page: IPublic }) {
    const templateName = page.template?.name
    const showIngressImage = !page.types.includes("PublicVideo") && page.image

    return (
        <StyledArticleHeader>
            {templateName && (
                <ArticleTemplateName variant="overline">
                    <Icon
                        name="articleOutlined"
                        fontSize="small"
                    />
                    {templateName}
                </ArticleTemplateName>
            )}

            <Typography variant="h1">{page.title}</Typography>
            <StyledIngress variant="body2">{page.ingress}</StyledIngress>

            {showIngressImage && (
                <>
                    <ContentImage image={page.image} />
                    {page.image.caption && (
                        <StyledFigCaption
                            variant="caption"
                            component="figcaption">
                            {page.image.caption}
                        </StyledFigCaption>
                    )}
                </>
            )}
        </StyledArticleHeader>
    )
}

const StyledArticleHeader = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const ArticleTemplateName = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(2),
    textTransform: "unset",
    letterSpacing: "unset",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5)
}))

const StyledIngress = styled(Typography)(() => ({
    fontFamily: "FigtreeLight",
    fontSize: "1.375rem"
}))

const StyledFigCaption = styled(Typography)<{ component: string }>(
    ({ theme }) => ({
        marginTop: theme.spacing(-2),
        padding: theme.spacing(1, 0)
    })
)
