import NextHead from "next/head"
import { t } from "translations"

import locales from "@nhi/utils/string/locales"
import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IArticlesCard } from "data/contentData/interfaces/mongodb/IArticlesCard"
import { ICMPublic } from "data/contentData/interfaces/mongodb/ICMPublic"
import { IContent } from "data/contentData/interfaces/mongodb/IContent"
import { IList } from "data/contentData/interfaces/mongodb/IList"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { ITopic } from "data/contentData/interfaces/mongodb/ITopic"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"

import { IPagingContext } from "./Layout/PageComponent/PageDataProvider"

export default function Head({
    page,
    paging
}: {
    page: IPage
    paging: IPagingContext | null
}) {
    return (
        <NextHead>
            <title>{getTitle(page, paging)}</title>
            <meta
                name="description"
                content={getDescription(page)}
                key="desc"
            />
            <meta
                property="og:site_name"
                content={t.siteTitle}
            />
            <meta
                property="og:url"
                content={`${process.env.NEXT_PUBLIC_HOST}${getUrlWithPage(
                    removeTrailingSlashes(page.url),
                    paging
                )}`}
            />
            <meta
                property="og:title"
                content={getSocialMediaTitle(page as IPublic, paging)}
                key="title"
            />
            <meta
                property="og:description"
                content={getDescription(page)}
            />
            <meta
                property="og:image"
                content={getImage(page)}
            />
            <meta
                property="og:locale"
                content={locales[process.env.NEXT_PUBLIC_LOCALE]}
            />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
            />
            {page.noIndex && (
                <meta
                    name="robots"
                    content="noindex"
                />
            )}
            {[
                CONTENT_TYPE.ARTICLE,
                CONTENT_TYPE.LIST,
                CONTENT_TYPE.TOPIC
            ].includes(getContentType(page.types)) && (
                    <>
                        <link
                            rel="canonical"
                            href={`${process.env.NEXT_PUBLIC_HOST}${getUrlWithPage(
                                removeTrailingSlashes(page.url),
                                paging
                            )}`}
                        />
                        {paging && paging.paging.currentPageNumber !== 0 && (
                            <link
                                rel="prev"
                                href={`${process.env.NEXT_PUBLIC_HOST
                                    }${getPreviousUrl(removeTrailingSlashes(page.url), paging)}`}
                            />
                        )}
                        {paging &&
                            (paging.paging.currentPageNumber !==
                                paging.pages.length &&
                                paging.paging.next) && (
                                <link
                                    rel="next"
                                    href={`${process.env.NEXT_PUBLIC_HOST}${getNextUrl(removeTrailingSlashes(page.url), paging)}`}
                                />
                            )}
                        <meta
                            property="og:type"
                            content="article"
                        />
                        {(page as IPublic).authors?.map(author => (
                            <meta
                                key={author.contentGuid}
                                property="article:author"
                                content={removeTrailingSlashes(author.url)}
                            />
                        ))}
                        <meta
                            property="article:publisher"
                            content={process.env.NEXT_PUBLIC_HOST}
                        />
                        {page.published && (
                            <meta
                                property="article:published_time"
                                content={new Date(page.published).toISOString()}
                            />
                        )}
                        {(page as IArticle).latest && (
                            <meta
                                property="article:modified_time"
                                content={new Date(
                                    (page as IArticle).latest as Date
                                ).toISOString()}
                            />
                        )}
                        {page.unpublished && (
                            <meta
                                property="article:expiration_time"
                                content={new Date(page.unpublished).toISOString()}
                            />
                        )}
                        <meta
                            property="article:section"
                            content={page.parents?.at(-2)?.name ?? ""}
                        />
                        {(page as IPublic).keywords?.map(keyword => (
                            <meta
                                key={keyword}
                                property="article:tag"
                                content={keyword}
                            />
                        ))}
                    </>
                )}
        </NextHead>
    )
}

/**
 * get previous url path
 * @param pageUrl Get previous url path
 * @param previousPage article url
 * @returns return previous url path without page number if page number is 1
 */
function getPreviousUrl(pageUrl: string, paging: IPagingContext | null) {
    if (!paging?.paging.prev) return pageUrl

    if (pageUrl.endsWith("/"))
        return `${pageUrl}${paging.paging.prev}`
    else
        return `${pageUrl}/${paging.paging.prev}`
}

function getNextUrl(pageUrl: string, paging: IPagingContext | null) {
    if (!paging?.paging.next) return pageUrl
    if (pageUrl.endsWith("/"))
        return `${pageUrl}${paging.paging.next}`
    else
        return `${pageUrl}/${paging.paging.next}`
}

/**
 * get url with page parameter
 * @param pageUrl url of page
 * @param paginate whether the article is paginated or not
 * @param pageNumber current paged number
 * @param pageParam page param
 * @returns page url suffixed with page param
 */
function getUrlWithPage(pageUrl: string, paging: IPagingContext | null) {
    if (!paging) return pageUrl

    if (pageUrl.endsWith("/"))
        return `${pageUrl}${paging.paging.current}`
    else
        return `${pageUrl}/${paging.paging.current}`
}

/**
 * get paged section title
 * @param pages page sections
 * @param pageNumber current paged number
 * @param paginate whether the article is paginated or not
 * @param pageParam page parma
 * @returns name of sub section
 */
function getPagedTitle(paging: IPagingContext | null) {
    if (!paging) return ""

    if (
        paging.paging.currentPageNumber !== null &&
        paging.paging.currentPageNumber > 0
    )
        return paging.pages[paging.paging.currentPageNumber]?.name

    return ""
}

/**
 * remove trailing slashes
 * @param pageUrl page url
 * @returns return page url without trailing slashes
 */
function removeTrailingSlashes(pageUrl: string) {
    if (pageUrl?.endsWith("/")) return pageUrl.substring(0, pageUrl.length - 1)
    return pageUrl
}

/**
 * function that extracts title from page object
 * @param page the current page
 * @returns title of current page
 */
function getTitle(page: IPage, paging: IPagingContext | null) {
    let title: string
    if (page.metadata && page.metadata.title) title = page.metadata.title
    else title = page.name

    const pagedTitle = getPagedTitle(paging)
    if (pagedTitle) title = `${title} - ${pagedTitle}`

    if (page.url !== "/") title = `${title} - ${t.siteTitle}`

    return title
}

/**
 * function that extracts social media title from page object
 * @param page the current page
 * @returns title of current page
 */
function getSocialMediaTitle(page: IPublic, paging: IPagingContext | null) {
    let title: string
    if (page.metadata && page.metadata.title) title = page.metadata.title
    else title = page.title

    if (!title) return getTitle(page, paging)

    const pagedTitle = getPagedTitle(paging)
    if (pagedTitle) title = `${title} - ${pagedTitle}`

    if (page.url !== "/") title = `${title} - ${t.siteTitle}`

    return title
}

/**
 * pulls a description of the page from the page's object based on page type
 * @param page the current page
 * @returns description of the current page
 */
function getDescription(page: IPage) {
    if (page.metadata && page.metadata.description)
        return page.metadata.description

    switch (getContentType(page.types)) {
        case CONTENT_TYPE.START:
        case CONTENT_TYPE.TOPIC:
            return getDescriptionFromTopic(page as ITopic)
        case CONTENT_TYPE.SYSTEM:
            return getDescriptionFromParentMetadata(page)
        case CONTENT_TYPE.LIST:
            return getDescriptionFromList(page as IList)
        case CONTENT_TYPE.ARTICLE:
            return getDescriptionFromPublic(page as IPublic)
        case CONTENT_TYPE.CONTENTMARKETING_ARTICLE:
            return getDescriptionFromPublic(page as ICMPublic)
        default:
            throw new Error(`Unknown content type: ${page.types.join("-")} `)
    }
}

/**
 * pulls description from page object that is typed as IPage by using parent's
 * metadata description
 * @param page page object typed as IPage
 * @returns a cut description of current page
 */

function getDescriptionFromParentMetadata(page: IPage) {
    const description =
        page.parents && page.parents.length > 0
            ? page.parents[page.parents.length - 1].metadata.description
            : ""
    return cutDescription(description)
}

/**
 * pulls description from page object that is typed as ITopic by adding together
 * multiple description names and titles.
 * @param topic page object typed as ITopic
 * @returns a cut description of current page based on parent's metadata
 */

function getDescriptionFromTopic(topic: ITopic) {
    let description = ""

    const cards: IContent[] = topic.main
        .filter(x => getContentType(x.types) === CONTENT_TYPE.CARD_CONTENT)
        .map(x => x as IContent)
    const articles: IArticlesCard[] = topic.main
        .filter(x => getContentType(x.types) === CONTENT_TYPE.CARD_ARTICLES)
        .map(x => x as IArticlesCard)

    for (const card of cards) {
        if (description.length > 300) break
        description = `${description}${description.length > 0 ? ", " : ""}${card.name
            }`
    }

    for (const article of articles
        .flatMap(x => x.articles)
        .filter(x => x?.title)) {
        if (description.length > 300) break
        description = `${description}${description.length > 0 ? ", " : ""}${article.title
            }`
    }

    return cutDescription(description)
}

/**
 * pulls logo or image address from topic object.
 * @param topic page object typed as ITopic
 * @returns will return logo or article image address based what page is being loaded
 */

function getImageFromTopic(topic: ITopic) {
    if (topic.url === "/")
        return `${`${process.env.NEXT_PUBLIC_HOST}/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`}`

    const articles: IArticlesCard[] = topic.main
        ?.filter(x => getContentType(x.types) === CONTENT_TYPE.CARD_ARTICLES)
        .map(x => x as IArticlesCard)

    if (articles)
        for (const article of articles
            .flatMap(x => x.articles)
            .filter(x => x?.image?.src))
            return article.image?.src

    return `${`${process.env.NEXT_PUBLIC_HOST}/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`}`
}

/**
 * pulls description from page object typed as IPublic
 * @param descriptionPage page object typed as IPublic
 * @returns a cut description of the current page ingress
 */

function getDescriptionFromPublic(descriptionPage: IPublic) {
    let description = descriptionPage.ingress?.trim()

    if (!description)
        description = descriptionPage.sections
            ?.filter(x => x.type === "section")?.[0]
            ?.blockContent?.[0]?.children?.[0]?.text?.trim()

    if (!description) {
        description = ""
        const blockContentFilter = ["normal", "p", "li"]
        const blockContents: any[] = descriptionPage.sections?.filter(
            x => x.type === "summary"
        )?.[0]?.blockContent

        const filteredBlockContents = blockContents?.filter(x =>
            blockContentFilter.some(bcf => x.style === bcf)
        )
        if (filteredBlockContents)
            for (const blockContent of filteredBlockContents) {
                if (description.length > 160) break
                description += ` ${blockContent.children?.[0]?.text?.trim()}`
            }
    }

    return cutDescription(description)
}

/**
 * pulls a description from page object typed as IList by adding together children
 *  objects names.
 * @param list page object typed as IList
 * @returns cut description of current page's description based on children
 * object's names
 */

function getDescriptionFromList(list: IList) {
    let description = `${list.name}: `

    for (const child of list.children ?? []) {
        if (description.length > 160) break
        description = `${description}${description.length > `${list.name}: `.length ? ", " : ""
            }${child.name}`
    }

    return cutDescription(description)
}

/**
 * reduces the description length to the last comma or returns an empty string if
 * there is no description
 * @param description a string comtaning the description of the current page
 * @returns either an empty string or a trim()ed and cut string.
 */

function cutDescription(description: string) {
    if (!description) return ""

    return description.trim().replace(/(\r\n|\n|\r)/gm, "")
}

/**
 * pulls different image addresses based on page type.
 * @param page page object
 * @returns logo or image address
 */

function getImage(page: IPage) {
    switch (getContentType(page.types)) {
        case CONTENT_TYPE.START:
        case CONTENT_TYPE.SYSTEM:
        case CONTENT_TYPE.TOPIC:
            return getImageFromTopic(page as ITopic)
        case CONTENT_TYPE.LIST:
            return `${`${process.env.NEXT_PUBLIC_HOST}/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`}`
        case CONTENT_TYPE.ARTICLE:
        case CONTENT_TYPE.CONTENTMARKETING_ARTICLE:
            return (page as IPublic).image?.src || ""
        default:
            throw new Error(`Unknown content type: ${page.types.join("-")} `)
    }
}
